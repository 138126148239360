<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="order-list-wrap">
          <b-container fluid>
            <h2 class="page-title">我的订单</h2>
            <b-row>
              <b-col>
                <div class="order-status">
                  <div :class="activeStatusIndex == index ? 'item active' : 'item'" v-for="(item, index) in orderStatus"
                    :key="index" @click="orderStatusChange(item, index)">{{item.title}}</div>
                </div>
                <div class="order-info-item-title">
                  <div class="info-item-title">商品</div>
                  <div class="info-item-title">单价(元)</div>
                  <div class="info-item-title">数量</div>
                  <div class="info-item-title">售后</div>
                  <div class="info-item-title">订单总额</div>
                  <div class="info-item-title">交易状态</div>
                  <div class="info-item-title">操作</div>
                </div>
                <div class="order-items">
                  <div class="order-item" v-for="(item, index) in orders" :key="index">
                    <div class="item">
                      <div class="order-title-info">
                        <span>订单号：{{item.order_id}}</span>
                        <span>{{item.create_date}}</span>
                      </div>
                      <div class="order-product-info">
                        <div class="pic" @click="toProductDetail(item.project_id)">
                          <img :src="item.img_path" :alt="item.goods_name">
                        </div>
                        <div class="product-params">
                          <div class="name" @click="toProductDetail(item.project_id)">{{item.goods_name}}</div>
                          <div class="params">{{item.goods_parameter}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="item">￥{{item.unit_price}}</div>
                    <div class="item">{{item.goods_num}}</div>
                    <div class="item">全国联保</div>
                    <div class="item">￥{{item.total_price}}</div>
                    <div class="item">
                      <span class="order-state">{{orderStated(index)}}</span>
                      <span class="order-detail" @click="toOrderDetail(item.order_id)">订单详情</span>
                    </div>
                    <div class="item">
                      <div class="operation-btn dang" v-if="item.order_status == 0"
                        @click="immediatePayment(item.order_id)">立即支付</div>
                      <div class="operation-btn warn" v-if="item.order_status == 3" @click="confirmReceipt(item.order_id)">确认收货</div>
                      <div class="operation-btn dang" v-if="item.order_status == 4 || item.order_status == 5"
                        @click="buyAgain(item)">再次购买</div>
                      <div class="operation-btn grey" v-if="item.order_status == 4" @click="evaluate(item.order_id)">评价</div>
                      <div class="operation-btn dang" v-if="item.order_status == 0" @click="cancelOrder(item.order_id)">取消订单</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="pagination">
                  <el-pagination
                    background
                    hide-on-single-page
                    layout="pager"
                    @current-change="pageSizeChange"
                    :page-size="pageSize"
                    :total="total">
                  </el-pagination>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <!-- <right></right> -->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  // import Right from "@/components/Right.vue";
  import FooterNav from "@/components/Footer.vue";
  import dump from "@/util/dump";
  export default {
    components: {
      headNav,
      sideBar,
      FooterNav,
      // Right
    },
    data() {
      return {
        orderStatus: [{
            title: "全部订单",
            id: ""
          },
          {
            title: "待付款",
            id: 1
          },
          {
            title: "待发货",
            id: 2
          },
          {
            title: "待收货",
            id: 3
          }
        ],
        activeStatusIndex: 0,
        orderState: "",
        token: "",
        currentPage: 1,
        orders: [],
        pageSize: 10,
        total: 0
      }
    },
    created() {
      this.init();
    },
    computed: {
      orderStated() {
        return i => {
          let orderState = "";
          switch (this.orders[i].order_status) {
            case 0:
              orderState = "待付款";
              break;
            case 1:
              orderState = "已付款";
              break;
            case 2:
              orderState = "待发货";
              break;
            case 3:
              orderState = "待收货";
              break;
            case 4:
              orderState = "已完成";
              break;
            case 5:
              orderState = "已关闭";
              break;
            case 6:
              orderState = "申请退款";
              break;
            case 7:
              orderState = "已退款";
              break;
            case 8:
              orderState = "退款驳回";
              break;
          }
          return orderState;
        };
      }
    },
    methods: {
      init() {
        this.orderState = this.$route.query.status ? this.$route.query.status : "";
        this.activeStatusIndex = this.$route.query.activeStatusIndex ? this.$route.query.activeStatusIndex : 0;
        this.token = localStorage.getItem("token");
        this.getOrders();
      },
      // 点击切换不同状态的订单
      orderStatusChange(obj, i) {
        this.activeStatusIndex = i;
        this.orderState = obj.id;
        this.getOrders();
      },
      // 获取用户订单
      getOrders() {
        this.$api.orders({
          status: this.orderState,
          currentPage: this.currentPage,
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            this.total = res.data.totalResult;
            this.orders = res.data.orders;
          }
        }).catch(err => console.error(err))
      },
      // 跳转到商品详情
      toProductDetail(goodsId){
        const params = {
          type: 1,
          link: "/details",
          params: {
            good_id: goodsId
          }
        }
        dump.link(params);
      },
      // 跳转到订单详情
      toOrderDetail(orderId) {
        const params = {
          type: 1,
          link: "/usercenter/orderDetail",
          params: {
            order_id: orderId
          }
        }
        dump.link(params);
      },
      // 立即支付
      immediatePayment(orderId) {
        if (!dump.checkLogin()) {
          return false;
        }
        const params = {
          type: 1,
          link: "/orderSettlement" + orderId,
          params: {
            orderid: orderId
          }
        }
        
        dump.link(params);
      },
      // 确认收货
      confirmReceipt(orderId) {
        if (!dump.checkLogin()) {
          return false;
        }
        this.$api.confirmCollectGoods({
          order_id: orderId,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.$message({
              message: '确认收货成功',
              type: 'success'
            });
            this.getOrders();
          }
        }).catch(err => console.error(err))
      },
      // 再次购买 /product_detail.html?good_id=5012612
      buyAgain(obj) {
        if(obj.is_line == 1){
          const params = {
            type: 2,
            link: "product_detail.html?good_id=" + obj.project_id
          }
          dump.link(params);
        }else{
          this.$message.error('该商品已下架');
        }
      },
      // 评价
      evaluate(orderId) {
        if (!dump.checkLogin()) {
          return false;
        }
        const params = {
          type: 2,
          link: "order_detail.html?order_id=" + orderId
        }
        dump.link(params);
      },
      // 取消订单
      cancelOrder(orderId) {
        if (!dump.checkLogin()) {
          return false;
        }
        this.$api.cancelOrder({
          order_id: orderId,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.$message({
              message: '取消成功',
              type: 'success'
            });
            this.getOrders();
          }
        }).catch(err => console.error(err))
      },
      // 分页切换
      pageSizeChange(num){
        this.currentPage = num;
        this.getOrders();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/orderManagement/orderManagement";
</style>
